/* eslint-disable camelcase */
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/styles';
import { format, isValid, parseISO } from 'date-fns';
import { Field, Formik } from 'formik';
import React from 'react';

import { PrimaryButton } from 'src/components/general/PrimaryButton';
import { SecondaryButton } from 'src/components/general/SecondaryButton';
import {
  Staff_LabResultInvalidReason as LabResultInvalidReason,
  Staff_LabResultStatus as LabResultStatus,
} from 'src/generated/gql/graphql';
import { PatientSubmittedTestResult } from 'src/labs/types';
import Colors from 'src/nightingale/Colors';
import { BooleanControl } from 'src/nightingale/components/ChartProperty/controls/Boolean/BooleanControl';
import { TextControl } from 'src/nightingale/components/ChartProperty/controls/Text/TextControl';

const invalidReasonOptions = [
  { label: 'Image too blurry to interpret', value: LabResultInvalidReason.TooBlurry },
  { label: `Image doesn't contain POCT cup`, value: LabResultInvalidReason.CupMissing },
  { label: 'Cup empty', value: LabResultInvalidReason.CupEmpty },
  { label: 'Cup is not Boulder-issued', value: LabResultInvalidReason.WrongCup },
  { label: 'Other (describe in notes)', value: LabResultInvalidReason.Other },
];

export const EditLabResultFormV2: React.FC<{
  images: PatientSubmittedTestResult['images'];
  item: Partial<PatientSubmittedTestResult['result']>;
  onCancel: () => void;
  onSaveAsDraft: (values: Partial<PatientSubmittedTestResult['result']>) => Promise<void>;
  onSignAndResult: (values: Partial<PatientSubmittedTestResult['result']>) => Promise<void>;
}> = ({ images, item, onCancel, onSaveAsDraft, onSignAndResult }) => {
  const classes = useStyles();
  const isEditable = item.status !== LabResultStatus.Resulted;
  const isIncomplete = item.status === LabResultStatus.Incomplete;
  const isResultSigned = !!item.signedBy;

  return (
    <>
      <figure className={classes.images}>
        {images.map((image, index) => (
          <img
            className={classes.resultImage}
            src={image.url}
            alt={`Lab result upload #${index + 1}`}
          />
        ))}
      </figure>

      <Formik
        initialValues={item}
        onSubmit={(values, actions) => {
          onSignAndResult(values).finally(() => {
            actions.setSubmitting(false);
          });
        }}
      >
        {({ handleSubmit, isSubmitting, dirty, values }) => {
          const notesChanged = values.notes !== item.notes;
          return (
            <form className={classes.form} onSubmit={handleSubmit}>
              <Field
                label="Result valid?"
                name="verified"
                isEditable={isEditable}
                component={FormBooleanControl}
              />
              {values.verified && (
                <Field
                  label="Bup positive?"
                  name="bupPositive"
                  isEditable={isEditable}
                  component={FormBooleanControl}
                />
              )}
              {values.verified === false && (
                <Field
                  label="Why aren't the results valid?"
                  helpText="Select the primary issue"
                  name="invalidReason"
                  isEditable={isEditable}
                  component={FormRadioControl}
                />
              )}
              <Field label="Additional Notes" name="notes" multiline component={FormTextControl} />
              {!isEditable && (
                <span className={classes.resultedByText}>{buildResultedByText(item)}</span>
              )}

              <span className={classes.buttons}>
                <div>
                  <SecondaryButton onClick={() => onCancel()}>Cancel</SecondaryButton>
                  {isIncomplete && !isResultSigned && (
                    <>
                      <PrimaryButton
                        className={classes.button}
                        onClick={() => {
                          const filteredValues = {
                            ...values,
                            bupPositive: values.verified ? values.bupPositive : null,
                          };
                          onSaveAsDraft(filteredValues);
                        }}
                        disabled={isSubmitting || !dirty}
                      >
                        Save as Draft
                      </PrimaryButton>
                      <PrimaryButton
                        className={classes.button}
                        disabled={isSubmitting}
                        type="submit"
                      >
                        Sign and Result
                      </PrimaryButton>
                    </>
                  )}
                  {isResultSigned && notesChanged && (
                    <PrimaryButton
                      className={classes.button}
                      onClick={() => {
                        const filteredValues = {
                          ...values,
                          bupPositive: values.verified ? values.bupPositive : null,
                        };
                        onSaveAsDraft(filteredValues);
                      }}
                      disabled={isSubmitting || !dirty}
                    >
                      Save
                    </PrimaryButton>
                  )}
                </div>
              </span>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

const FormBooleanControl = ({ field, form, label, isEditable }) => (
  <BooleanControl
    label={label}
    name={field.name}
    onChangeValue={newVal => {
      form.setFieldValue(field.name, newVal);
    }}
    value={field.value}
    disabled={!isEditable}
  />
);

const FormTextControl = ({ field, form, label }) => (
  <TextControl
    label={label}
    multiline
    name={field.name}
    onChangeValue={newVal => {
      form.setFieldValue(field.name, newVal);
    }}
    value={field.value || ''}
  />
);

const FormRadioControl = ({ field, form, label, helpText, isEditable }) => {
  const styles = useStyles({});
  return (
    <div className={styles.radioControlContainer}>
      <InputLabel
        classes={{
          root: styles.label,
        }}
        shrink
      >
        <span>{label}</span>
      </InputLabel>
      {helpText && <span className={styles.helpText}>{helpText}</span>}
      <RadioGroup
        value={field.value}
        onChange={event => {
          form.setFieldValue(field.name, event.target.value);
        }}
      >
        {invalidReasonOptions.map(optionProps => (
          <FormControlLabel control={<Radio />} {...optionProps} disabled={!isEditable} />
        ))}
      </RadioGroup>
    </div>
  );
};

const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30,
    '& .MuiFormControl-root': {
      marginBottom: 16,
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: 20,
  },
  button: {
    marginLeft: 8,
  },
  images: {
    alignItems: 'center',
    columnGap: 20,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '0 0 20px',
    rowGap: 20,
  },
  resultImage: {
    display: 'block',
    height: 'auto',
    margin: 0,
    marginBottom: '20px',
    maxWidth: '90vh',
    width: '100%',
  },
  label: {
    fontFamily: '"Nunito", "Nunito Sans"',
    fontWeight: 600,
    fontSize: 10,
    lineHeight: '14.5px',
    letterSpacing: `.12em`,
    '&, &.Mui-error, &.Mui-focused': {
      color: Colors.Gray6,
    },
    textTransform: 'uppercase',
    transform: 'scale(1)',
    position: 'static',
  },
  helpText: {
    fontSize: 12,
    fontFamily: '"Nunito", "Nunito Sans"',
    fontWeight: 600,
    fontStyle: 'italic',
    '&, &.Mui-error, &.Mui-focused': {
      color: Colors.Gray6,
    },
    transform: 'scale(1)',
    position: 'static',
  },
  radioControlContainer: {
    marginTop: 4,
  },
  resultedByText: {
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 13,
    fontStyle: 'italic',
  },
});

/**
 * Builds a string of the form "Resulted by {firstName} {lastName} on {date/time}", but leaving
 * out any data this is not populated on the input `item`.
 */
function buildResultedByText(item: Partial<PatientSubmittedTestResult['result']>): string {
  let text = 'Resulted';

  if (item.signedBy?.firstName || item.signedBy?.lastName) {
    text += ` by ${item.signedBy?.firstName || '(first name unknown)'} ${
      item.signedBy?.lastName || '(last name unknown)'
    }`;
  }

  if (item.signedAt) {
    const signedAt = parseISO(item.signedAt);
    if (isValid(signedAt)) {
      text += ` on ${format(signedAt, 'PPPppp')}`;
    }
  }

  return text;
}
